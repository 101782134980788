import { Proto } from "./Proto";
import { ValueOf } from "./Util";

export namespace SearchCriteria {
    import NumericalString = Proto.NumericalString;
    export type Model = {
        query_method?: "any" | "all";
        step: Step[];
    };

    export type Step = {
        prefix?: "r25";
        status?: "est" | "new" | "del";
        step_number?: NumericalString;
        step_param: StepParam[];
        step_type_id?: NumericalString | number;
        sub_step_type_id?: NumericalString | number;
        step_type_name?: string;
        qualifier?: number | NumericalString;
        qualifier_name?: "Include Any" | "Include All";
        contact_role_id?: number | NumericalString;
        contact_role_name?: string;
        contactBeans?: Step[];
        dateBean?: Step[];
        states?: {
            cancelled: boolean;
            completed: boolean;
            denied: boolean;
            outstanding: boolean;
            unread: boolean;
        };
        types?: {
            assignment: boolean;
            authorization: boolean;
            fyi: boolean;
            todo: boolean;
            vcal: boolean;
        };
    };

    export type StepParam = any;

    export type Searches = { [key: string]: Model };

    export type StepTypeId =
        | ValueOf<typeof StepType.Event>
        | ValueOf<typeof StepType.Location>
        | ValueOf<typeof StepType.Resource>
        | ValueOf<typeof StepType.Organization>
        | ValueOf<typeof StepType.Task>;

    export const StepType = {
        Event: {
            Favorites: 10,
            Details: 100,
            Keyword: 145,
            Events: 107,
            EventSearch: 108,
            EventSearchExclude: 192,
            Folders: 104,
            Cabinets: 105,
            Types: 102,
            TypesExclude: 103,
            States: 101,
            Organizations: 110,
            OrganizationsExclude: 112,
            SponsoringOrganizations: 113,
            Contacts: 111,
            Categories: 120,
            CategoriesExclude: 121,
            Requirements: 130,
            RequirementsExclude: 131,
            CustomAttributes: 150,
            Occurrences: 140,
            ReservationDetails: 141,
            CreatedDate: 143,
            AssignedSpaces: 163,
            SpacePreferences: 165,
            SpaceAssignments: 162,
            MissingSpaceAssignments: 160,
            AssignedResources: 168,
            ResourcesAssignments: 167,
            OutstandingNeedsSpaceVCalendarToDos: 174,
            OutstandingExceedsHeadcountVCalendarToDos: 175,
            OutstandingExportVCalendarToDos: 176,
            IncludeRelatedEvents: 20,
            ProfileCode: 146,
            ProfileCodeNone: 147,
            StandardSchedule: 148,
        } as const,

        Location: {
            Favorites: 10,
            Details: 400,
            Keyword: 445,
            Search: 405,
            Spaces: 407,
            Buildings: 425,
            BuildingsExclude: 426,
            Partitions: 423,
            PartitionsExclude: 424,
            Features: 410,
            FeaturesExclude: 411,
            Categories: 420,
            CategoriesExclude: 421,
            Layouts: 430,
            LayoutsExclude: 433,
            SpaceRelationships: 460,
            SpaceRelationshipsExclude: 461,
            CustomAttributes: 450,
            SpaceAssignments: 470,
            AssignedSpaces: 471,
            IncludeRelatedSpaces: 20,
        } as const,

        Organization: {
            Favorites: 10,
            Details: 200,
            Keyword: 245,
            Search: 205,
            Organizations: 207,
            Address: 240,
            Categories: 220,
            Categories2: 221,
            Contacts: 210,
            Types: 230,
            Types2: 231,
            CustomAttributes: 250,
            Ratings: 280,
            Ratings2: 281,
            OrganizationAssignments: 270,
            AssignedOrganizations: 271,
        } as const,

        Resource: {
            Favorites: 10,
            Details: 600,
            Keyword: 645,
            Search: 605,
            Resources: 607,
            Categories: 620,
            Categories2: 621,
            Relationships: 660,
            Relationships2: 661,
            CustomAttributes: 650,
            ResourceAssignments: 670,
            AssignedResources: 671,
        } as const,

        Task: {
            Any: 1000,
        } as const,
    } as const;
}
