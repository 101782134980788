import { ContactService } from "./contact.service";
import { FlsService } from "./fls.service";
import { OrganizationService } from "./organization.service";
import { ResourceService } from "./resource.service";
import { SpaceService } from "./space.service";
import { S25Util } from "../util/s25-util";
import { OlsService } from "./ols.service";
import { NotificationPolicyService } from "./notification.policy.service";
import { Fls } from "../pojo/Fls";
import { Item } from "../pojo/Item";

/***
 * CopyObject class handles copying of an object (location/resource/organiztaion/contact)
 */

interface serviceMap {
    copy: any;
    getName: any;
    flsName: keyof Fls;
    olsName?: keyof Fls;
    overrideOls?: keyof Fls;
    notifyName?: keyof Fls;
    assignName?: keyof Fls;
}

const SERVICE_MAP: { [key: number]: serviceMap } = {
    2: {
        copy: OrganizationService.copyOrganization,
        getName: OrganizationService.getOrganizationName,
        flsName: "CU_ACCOUNT",
        olsName: "ACCOUNT_SECURITY",
        overrideOls: "CU_PERM",
        notifyName: "CU_ACCT_NOTIFY",
    },
    3: {
        copy: ContactService.copyContact,
        getName: ContactService.getContactName,
        flsName: "CU_CONTACT",
    },
    4: {
        copy: SpaceService.copySpace,
        getName: SpaceService.getSpaceName,
        flsName: "SPACE_LIST",
        olsName: "SPACE_SECURITY",
        overrideOls: "SPACE_PERM",
        notifyName: "SPACE_NOTIFY",
        assignName: "SPACE_ASSIGN",
    },
    6: {
        copy: ResourceService.copyResource,
        getName: ResourceService.getResourceName,
        flsName: "RESOURCE_LIST",
        olsName: "RESOURCE_SECURITY",
        overrideOls: "RESOURCE_PERM",
        notifyName: "RESOURCE_ASSIGN",
        assignName: "RESOURCE_NOTIFY",
    },
};

export interface CopyPropertiesI {
    ap?: objPropsI;
    ols: objPropsI;
    notify: objPropsI;
    apDates?: objPropsI;
    apDOW?: objPropsI;
    apWindows?: objPropsI;
}

export interface objPropsI {
    selected: boolean;
    hasPerms?: boolean;
    itemId?: number;
    itemName: string;
    msg?: string;
}

export class CopyObjectService {
    public static async hasOlsOverride(itemTypeId: number) {
        const fls = await FlsService.getFls();
        return [2, 4, 6].includes(itemTypeId)
            ? fls[CopyObjectService.serviceMap[itemTypeId].overrideOls] === "F"
            : true;
    }

    /**
     * To copy an object the user needs
     * FLS: *_LIST = F
     * They Also need OLS full control for the objects that have ols - could be via override
     */
    public static async canCopy(itemTypeId: number, itemId: Item.Id) {
        //Start with everything as true
        let flsAllowed;
        let olsAllowed = true;

        const fls = await FlsService.getFls();
        flsAllowed = fls[CopyObjectService.serviceMap[itemTypeId].flsName] === "F";

        //Things with OLS need further checks
        if (flsAllowed && [Item.Ids.Organization, Item.Ids.Location, Item.Ids.Resource].includes(itemTypeId)) {
            const hasOverride = await CopyObjectService.hasOlsOverride(itemTypeId);

            if (!hasOverride) {
                try {
                    const ols = await OlsService.getOls([itemId], itemTypeId, "edit");
                    olsAllowed = ols[0]?.access_level === "F";
                } catch (e) {
                    console.log(`Potential trouble with Ols${e}`);
                }
            }
        }

        return flsAllowed && olsAllowed;
    }

    public static copyRights = function (itemTypeId: number) {
        return FlsService.getFls().then((fls) => {
            return {
                //With new services rights to edit OLS/AP are no longer needed to  -travis
                ols: true, //"F" === fls[SERVICE_MAP[itemTypeId].olsName as keyof Fls],
                ap: true, //"F" === fls[SERVICE_MAP[itemTypeId].assignName as keyof Fls],
                notify: "F" === fls[SERVICE_MAP[itemTypeId].notifyName as keyof Fls],
            };
        });
    };

    public static copyObject = function (
        itemTypeId: number,
        itemId: number,
        newItem: any,
        properties?: CopyPropertiesI,
    ): Promise<{ itemId: number; security: any; error: any }> {
        const securityUrl = getUrlParams(itemId, itemTypeId, properties);
        return SERVICE_MAP[itemTypeId].copy(itemId, newItem, properties, securityUrl).then((dstId: number) => {
            //With new AP and schedule perms, it is safer to copy ols first since new AP includes some OLS info in the response
            return S25Util.all({
                // security: securityPromise,
                notify: properties?.notify?.selected && NotificationPolicyService.copy(itemTypeId, itemId, dstId),
            }).then(
                (resp) => {
                    return {
                        itemId: dstId,
                        security: resp.security,
                    };
                },
                (err) => {
                    S25Util.showError(err, "Error Copying Security");
                    return {
                        itemId: dstId,
                        error: err,
                    };
                },
            );
        });
    };

    public static getOriginal = function (
        itemTypeId: number,
        itemId: number,
    ): Promise<{ itemName: string; itemFormal?: string }> {
        switch (itemTypeId) {
            case 2:
                return OrganizationService.getOrganizationNameAndFormal(itemId).then((resp) => {
                    resp.itemName = appendCopy(resp.itemName);
                    resp.itemFormal = appendCopy(resp.itemFormal);
                    return resp;
                });
            case 3:
                return ContactService.getContact(itemId, "extended", ["address"]).then((resp) => {
                    return resp?.contacts?.contact;
                });
            case 4:
                return SpaceService.getSpaceNameAndFormal(itemId).then((resp) => {
                    resp.itemName = appendCopy(resp.itemName);
                    resp.itemFormal = appendCopy(resp.itemFormal);
                    return resp;
                });
            case 6:
                return ResourceService.getResourceName(itemId).then((resp) => {
                    resp = appendCopy(resp);
                    return { itemName: resp };
                });
        }
    };

    public static serviceMap = SERVICE_MAP;
}

function getUrlParams(sourceId: number, itemTypeId: number, security: CopyPropertiesI) {
    if (Item.Ids.Contact === itemTypeId) return "";
    let url = `ols_source=${security?.ols?.selected ? sourceId : "default"}`;
    if ([Item.Ids.Resource, Item.Ids.Location].includes(itemTypeId)) {
        const apSelected = security?.ap?.selected;
        url = url.concat(`&ap_source=${apSelected ? sourceId : "default"}`);
        url = url.concat(`&copy_dates=${apSelected && security?.apDates?.selected ? "t" : "f"}`);
        url = url.concat(`&copy_dow=${apSelected && security?.apDOW?.selected ? "t" : "f"}`);
        url = url.concat(`&copy_buffers=${apSelected && security?.apWindows?.selected ? "t" : "f"}`);
    }
    return url;
}
function appendCopy(name: string) {
    name = name + " (Copy)";
    return name.trim();
}

export const FIELDS_MAP = {
    6: {
        instructions: {
            itemName: "Default Instructions",
            selected: true,
            hasPerms: true,
        },
        comments: {
            itemName: "Comments",
            selected: true,
            hasPerms: true,
        },
        categories: {
            itemName: "Instructions",
            selected: true,
            hasPerms: true,
        },
        stock: {
            itemName: "Inventory",
            selected: true,
            hasPerms: true,
        },
        relationships: {
            itemName: "Related Resources",
            selected: true,
            hasPerms: true,
        },
    },
};
