//@author: devin
import {
    ApplicationRef,
    ComponentFactoryResolver,
    ComponentRef,
    EmbeddedViewRef,
    Injectable,
    Injector,
    NgZone,
} from "@angular/core";
import { jSith } from "../util/jquery-replacement";

export interface DomResp {
    domElem?: HTMLElement;
    componentRef?: ComponentRef<any>;
    instance?: any;
    destroy: Function;
}

@Injectable({
    providedIn: "root",
})
export class DomService {
    constructor(
        private componentFactoryResolver: ComponentFactoryResolver,
        private appRef: ApplicationRef,
        private injector: Injector,
        private zone: NgZone,
    ) {}

    getComponentRefAndElement(component: any, model?: any): Promise<DomResp> {
        let defer = jSith.defer();

        //create a component reference from the component
        const componentRef = this.componentFactoryResolver.resolveComponentFactory(component).create(this.injector);

        const instance: any = componentRef.instance;
        model &&
            instance &&
            jSith.forEach(model, (key: any, value: any) => {
                instance[key] = value;
            });

        //attach component to the appRef so that it's inside the ng component tree
        this.appRef.attachView(componentRef.hostView);

        //get DOM element from component
        const domElem = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;

        this.zone.run(() => {
            setTimeout(() => {
                let domResp: DomResp = {
                    domElem: domElem,
                    componentRef: componentRef,
                    instance: instance,
                    destroy: () => {
                        componentRef && componentRef.hostView && this.appRef.detachView(componentRef.hostView);
                        componentRef && componentRef.destroy();
                    },
                };
                defer.resolve(domResp);
            }, 1);
        });

        return defer.promise;
    }

    appendComponentToTarget(component: any, target?: HTMLElement, model?: any): Promise<DomResp> {
        target = target || document.body;
        return this.getComponentRefAndElement(component, model).then((domResp) => {
            target.appendChild(domResp.domElem); //append DOM element to the target
            return domResp;
        });
    }

    appendComponentToBody(component: any, model?: any): Promise<DomResp> {
        return this.appendComponentToTarget(component, document.body, model);
    }
}
